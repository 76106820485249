import React from "react"
import { graphql } from "gatsby"





const ComponentName = () => (
  <div></div>
)


export default ComponentName
